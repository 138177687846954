<template>
  <div class="page">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <b-card
          class="memberContainer"
        >
          <b-button
            variant="outline-primary"
            class="mb-2"
            @click="$router.push({name: 'Create User'})"
          >
            <feather-icon icon="PlusIcon" />
            Add New User
          </b-button>
          <b-table
            responsive="xl"
            :items="users"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #cell(name)="data">
              <router-link :to="'/users/' + data.item.unique_id + '/' + data.item.admin">
                {{ data.value }}
              </router-link>
            </template>

            <template #cell(activation)="data">
              <div class="activateContainer">
                <div>
                  <feather-icon
                    v-if="data.value !== 1"
                    icon="XIcon"
                    size="20"
                    class="redIcon"
                  />
                  <feather-icon
                    v-else
                    icon="CheckIcon"
                    size="20"
                    class="greenIcon"
                  />
                </div>
              </div>
            </template>

            <template #cell(admin)="data">
              <div>
                <feather-icon
                  v-if="data.value !== 1"
                  icon="XIcon"
                  size="20"
                  class="redIcon"
                />
                <feather-icon
                  v-else
                  icon="CheckIcon"
                  size="20"
                  class="greenIcon"
                />
              </div>
            </template>

            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item :to="'/teams/' + data.item.unique_id + '/' + data.item.admin">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.activation === 0"
                  @click="onActivateClick(data.item)"
                >
                  <feather-icon
                    icon="UserPlusIcon"
                    class="mr-50"
                  />
                  <span>Activate</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-else
                  @click="onActivateClick(data.item)"
                >
                  <feather-icon
                    icon="UserXIcon"
                    class="mr-50"
                  />
                  <span>Deactivate</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.delete-modal
                  @click="onRemoveClick(data.item)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>Remove</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template #cell(role)="data">
              <span style="text-transform:capitalize">{{ data.value }}</span>
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            class="d-flex justify-content-center"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          />
        </b-card>
      </div>
    </div>
    <validation-observer
      ref="agentIdForm"
      #default="{invalid}"
    >
      <b-modal
        id="agent-id-modal"
        ref="activate-agent-modal"
        title="BootstrapVue"
        centered
        @ok="e => handleOk(e, invalid)"
      >
        <form @submit.stop.prevent="handleSubmit(invalid)">
          <b-form-group
            label="MLS Agent ID"
            label-for="modal-agent-id"
            invalid-feedback="MLS agent ID is required"
            :state="!invalid"
          >
            <validation-provider
              #default="{ errors }"
              name="modal-agent-id"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="modal-agent-id"
                  v-model="modalAgentId"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="modal-agent-id"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </form>
      </b-modal>
    </validation-observer>

    <validation-observer
      ref="deleteForm"
      #default="{invalid}"
    >
      <b-modal
        id="delete-modal"
        ref="delete-modal"
        title="Delete User"
        centered
        @ok="e => handleRemoveOk(e, invalid)"
      >
        <form
          @submit.stop.prevent="handleRemoveSubmit(invalid)"
        >

          <b-form-group
            label="Enter your Password to confirm"
            label-for="password"
            invalid-feedback="Password id required"
            :state="!invalid"
          >
            <validation-provider
              #default="{ errors }"
              name="password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="password"
                  v-model="confirmPassword"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="password"
                />

                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility('password')"
                  />
                </b-input-group-append>
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </form>
      </b-modal>
    </validation-observer>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BTable, BPagination, BButton, BFormGroup, BInputGroup, BFormInput, BDropdown, BDropdownItem, BInputGroupAppend,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard, BTable, BButton, BPagination, ValidationProvider, ValidationObserver, BFormGroup, BInputGroup, BFormInput, BDropdown, BDropdownItem, BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      required,
      modalAgentId: '',
      fields: ['name', 'role', 'agentID', 'email', 'phone', 'admin', 'activation', 'action'],
      team: {
        name: '',
        email: '',
        phone: '',
        profile_image: '',
      },
      users: [],
      memberToActive: {},
      memberToRemove: {},
      confirmPassword: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    rows() {
      return this.users.length
    },
  },
  created() {
    this.$store.dispatch('teams/getTeamMembers').then(response => {
      if (response.code === 200) {
        this.users = response.data.map(data => {
          return {
            id: data.id,
            name: data.name,
            role: data.role,
            agentID: data.agent_mls_loginName,
            email: data.email,
            phone: data.phone,
            admin: data.is_admin,
            created: data.created_at,
            unique_id: data.unique_id,
            activation: data.activation,
          }
        })
        this.team = {
          name: response.data[0].detail.name,
          email: response.data[0].detail.primary_email,
          phone: response.data[0].detail.primary_phone,
          profile_image: response.data[0].detail.profile_image,
        }
      }
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    onActivateClick(item) {
      if (!item.agentID && item.role === 'agent') {
        this.$bvModal.show('agent-id-modal')
        this.memberToActive = item
      } else {
        const shouldActivate = item.activation === 0
        const data = {
          unique_id: item.unique_id,
          activation: shouldActivate,
        }

        this.$store.dispatch('teams/activateMember', data).then(response => {
          if (response.code === 200) {
            const ele = this.users.find(element => element.unique_id === item.unique_id)

            if (ele.activation === 0) {
              ele.activation = 1

              this.showToast('User Activated', 'CheckCircleIcon', 'User has been activated successfully', 'success', 'bottom-right')
            } else {
              ele.activation = 0

              this.showToast('User Deactivated', 'CheckCircleIcon', 'User has been deactivated successfully', 'success', 'bottom-right')
            }

            this.memberToActive = {}
          }
        }).catch(err => {
          console.error(err)
        })
      }
    },
    handleOk(event, invalid) {
      event.preventDefault()
      this.handleSubmit(invalid)
    },
    handleSubmit(invalid) {
      if (!invalid) {
        const shouldActivate = this.memberToActive.activation === 0
        const data = {
          agent_mls_loginName: this.modalAgentId,
          unique_id: this.memberToActive.unique_id,
          activation: shouldActivate,
        }

        this.$store.dispatch('teams/activateMember', data).then(response => {
          if (response.code === 200) {
            const ele = this.users.find(element => element.unique_id === this.memberToActive.unique_id)

            if (ele.activation === 0) {
              ele.activation = 1

              this.showToast('User Activated', 'CheckCircleIcon', 'User has been activated successfully', 'success', 'bottom-right')
            } else {
              ele.activation = 0

              this.showToast('User Deactivated', 'CheckCircleIcon', 'User has been deactivated successfully', 'success', 'bottom-right')
            }

            this.memberToActive = {}
            this.$refs['activate-agent-modal'].hide()
          }
        }).catch(err => {
          console.error(err)
        })
      }
    },
    handleRemoveOk(event, invalid) {
      event.preventDefault()
      this.handleRemoveSubmit(invalid)
    },
    handleRemoveSubmit(invalid) {
      if (!invalid) {
        const data = {
          unique_id: this.memberToRemove.unique_id,
          password: this.confirmPassword,
        }
        this.$store.dispatch('teams/deleteUser', data).then(response => {
          if (response.code === 200) {
            this.$refs['delete-modal'].hide()

            const indexToRemove = this.users.findIndex(element => element.unique_id === this.memberToRemove.unique_id)
            this.users.splice(indexToRemove, 1)
            this.showToast('Success', 'CheckCircleIcon', 'User has been deleted successfully', 'danger', 'bottom-right')
          } else {
            this.showToast('Failed', 'AlertCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
          }
        }).catch(err => {
          console.error(err)
        })
      }
    },
    onRemoveClick(item) {
      this.memberToRemove = item
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style lang="scss">
.teamProfileImage{
  margin:20px auto;display:flex;
}

.activateContainer {
  display: flex;
  flex-direction: column;
}

</style>
